import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { GET_USER } from '../../../graphql/chart';
import { client } from "../../../graphql/Client";

const colors = scaleOrdinal(schemeCategory10).range();

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {

  const { fill, x, y, width, height, } = props;
 
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  
};

TriangleBar.propTypes = {

  fill: PropTypes.string,

  x: PropTypes.number,

  y: PropTypes.number,

  width: PropTypes.number,

  height: PropTypes.number,

};

export default class USERCHART extends PureComponent {

  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/rnywhbu8/';
 
  state = {

    activeIndex: 0,

    data: [
     
      { name: 'VP', value: 0 },
      
      { name: 'Director', value: 0 },

      { name: 'Manager', value: 0 },
      
      { name: 'Normal', value: 0 },

      { name: 'DISABLED', value:0, },

    ],

  };

  componentDidMount() {

    this.fetch_user();

  }

  // fecth slot

  fetch_user = () => {

    client.query({

      query: GET_USER,

      fetchPolicy: 'no-cache',

    }).then(result => {

      console.log(result.data.getUser.data);

      this.setState({ data: result.data.getUser.data });

    });

  }

  render() {

    const {data} =this.state;

    return (

      <BarChart className="users" width={500} height={300} data={data} margin={{ top: 20, right: 30, left: 10, bottom: 5, }} >

        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="name" fill="red" />

        <YAxis />

        <Bar dataKey="value" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>

          {

            data.map((entry, index) => (

              <Cell key={`cell-${index}`} fill={colors[index % 20]} />

            ))

          }
      
        </Bar>

      </BarChart>
    );
  }
}
