

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import gql from "graphql-tag";
import { Layout, Icon, Badge, Form, Input, Spin, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import ImageCompressor from 'image-compressor.js';
import { SEARCH_SLOT_REGEX } from "../../../graphql/slot";

const { TextArea } = Input;

const { Option } = Select;

const ADD_SLOT = gql`

    mutation ADDSLOT($slot_name: String, $slot_no: Int, $description: String ,$category: String ,$file:[Upload],$file1:[Upload],$lang:String) {   
      addSlot(slot_name: $slot_name,slot_no: $slot_no, description: $description,file:$file,file1:$file1,category: $category,lang:$lang)
        {
            _id,
            slot_name,
            slot_no,
            description,
            category,
            photo,
            msg,
            status
        }
    }
`;

const Uploads = (props) => {
    var form = props.form
    const [slot_no, setslot_no] = React.useState('');
    const [slot_name, setslot_name] = React.useState('');
    const [slot_search, set_slot_search] = React.useState('');
    const [description, setdescription] = React.useState('');
    const [category, setcategory] = React.useState('');
    const [file, setFile] = React.useState('');
    const [file1, setFile1] = React.useState('');
    const [imageUrl, setimageUrl] = React.useState('');
    const [imageUrl1, setimageUrl1] = React.useState('');
    const [up1, setupt] = React.useState('');
    const [spin, setspin] = React.useState(false);
    const [uploadFile] = useMutation(ADD_SLOT, {});
    const serach_slot_with_regex = useQuery(SEARCH_SLOT_REGEX);

    const getBase64 = (file) => {

        var reader = new FileReader();

        reader.readAsDataURL(file[0]);

        reader.onload = () => {

            console.log(reader.result);

            setimageUrl(reader.result);

        };

        reader.onerror = (error) => {

            console.log('Error: ', error);

        };

    }

    const getBase641 = (file) => {
        var reader1 = new FileReader();
        reader1.readAsDataURL(file[0]);
        reader1.onload = () => {
            setimageUrl1(reader1.result);
        };
        reader1.onerror = (error) => {
            console.log('Error: ', error);
        };
    }

    const onDrop = async acceptedFiles => {

        console.log(acceptedFiles);
        console.log(file);

        setupt('1');

        if (file.length === 1) {

            await getBase641(acceptedFiles);

            await new ImageCompressor(acceptedFiles[0], {

                quality: .6,
                success(result) {
                    console.log(result);
                    var file = new File([result], result.name);
                    console.log(file);
                    setFile1(file);
                }
            });
            // setFile1(acceptedFiles);

        } else if (file.length === 0) {

            await getBase64(acceptedFiles);

            await new ImageCompressor(acceptedFiles[0], {

                quality: .6,
                success(result) {
                    console.log(result);
                    var file = new File([result], result.name);
                    console.log(file);
                    setFile([file]);
                }
            });
            // setFile(acceptedFiles);

        }


    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: 0 });
    const onChange = event => setslot_name(event.target.value);
    const onChange1 = event => setslot_no(Number(event.target.value));
    const onChange2 = event => setdescription(event.target.value);
    const onChange3 = value => setcategory(value);
    const oncancel = () => { props.cancelmodel(false); }
    const handleSearch = async (svalue) => {
        if (svalue) {
            let input_data = {
                sname: svalue,
                sno: svalue,
                limit: 5,
                condition: 'OR'
            }
            let finaldata = await serach_slot_with_regex.refetch(input_data)
            if (finaldata.data && finaldata.data.serach_slot_with_regex) {
                set_slot_search(finaldata.data.serach_slot_with_regex)
            }
        }
    }
    const AddSlot = useCallback(
        (event) => {
            console.log(file);
            setspin(true);
            if (slot_no && slot_name && category) {
                console.log("oncancel -> category", category)
                let input_data = {
                    "category": category,
                    "slot_no": slot_no,
                    "slot_name": slot_name,
                    "description": description,
                    file,
                    file1,
                    lang: ens
                }

                uploadFile({
                    variables: input_data
                }).then(result => {
                    setspin(false);
                    console.log(result.data);
                    props.addslot(result.data);
                }).catch(error => {
                    setspin(false);
                    Alert({ msg:error.message, status: 'failed' });
                });
            } else {
                setspin(false);
                Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });
            }
        }, [uploadFile]
    );

    strings.setLanguage(ens);

    return (
        <div className="gutter-example">
            <Spin tip="Loading..." spinning={spin}  >
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={8}>
                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.sln} value={slot_name} onChange={onChange} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Input placeholder={strings.slno} value={slot_no} onChange={onChange1} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Badge status="processing" text="" />
                                    <Select defaultValue={strings.cat} 
                                    style={{width: '100%'}}
                                        // { width: '-webkit-fill-available' }} 
                                        onChange={onChange3}>
                                        <Option value="WORKING_STATION">Working Station</Option>
                                        <Option value="CASUAL_STATION">Casual Station</Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        {/* {(category && category === "TANDEM") && <div className='pb-3 text-warning'>Tendam will automatically create two slot (front, back) </div>} */}
                        <Row gutter={16}>
                            <Col className="gutter-row" span={16}>
                                <div style={{ marginBottom: 16 }}>
                                    <TextArea placeholder={strings.ds} value={description} onChange={onChange2} rows={6} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className="d-flex" style={{ marginBottom: 16 }}>
                                    <Icon type="caret-left" className={up1 === '1' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('1') }} />
                                    <div className={up1 === '1' ? 'vw-100 gutter-box' : up1 === '' ? 'vw-100 gutter-box' : 'd-none'}>
                                        <div {...getRootProps()} className={imageUrl === '' ? "slot_img_upload d-flex" : "d-none"} >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <p>Drop the files here ...</p>
                                            ) : (

                                                <Icon type="cloud-upload" className="m-auto icon" />
                                            )}
                                        </div>
                                        <img src={imageUrl} className={imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />
                                        <Icon type="delete" theme="twoTone" className={imageUrl === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl(''); setFile(''); }} />
                                    </div>
                                    <div className={up1 === '2' ? 'vw-100 gutter-box' : 'd-none'}>
                                        <div {...getRootProps()} className={imageUrl1 === '' ? "slot_img_upload d-flex" : "d-none"} >
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <p>Drop the files here ...</p>
                                            ) : (

                                                <Icon type="cloud-upload" className="m-auto icon" />
                                            )}
                                        </div>
                                        <img src={imageUrl1} className={imageUrl1 === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />
                                        <Icon type="delete" theme="twoTone" className={imageUrl1 === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl1(''); setFile1(''); }} />
                                    </div>
                                    <Icon type="caret-right" className={up1 === '2' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('2') }} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button key="back" onClick={oncancel}> Cancel </Button>
                                <Button className="float-right" key="submit" type="primary" onClick={AddSlot}> Submit </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </div >

    );
};

export default Form.create()(Uploads);
