import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector } from 'recharts';
import { GET_SLOT } from '../../../graphql/chart';
import { client } from "../../../graphql/Client";

const renderActiveShape = (props) => {

  const RADIAN = Math.PI / 180;
 
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, } = props;
  
  const sin = Math.sin(-RADIAN * midAngle);
 
  const cos = Math.cos(-RADIAN * midAngle);
  
  const sx = cx + (outerRadius + 10) * cos;
 
  const sy = cy + (outerRadius + 10) * sin;
 
  const mx = cx + (outerRadius + 30) * cos;
  
  const my = cy + (outerRadius + 30) * sin;
 
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
 
  const ey = my;
 
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (

    <g>

      <text x={cx} y={cy} dy={8} textAnchor="middle" style={{ fontSize: 'initial' }} fill={fill}>{payload.name}</text>

      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />

      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />

      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />

      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`NO : ${value}`}</text>

      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">

        {`${(percent * 100).toFixed(2)}%)`}

      </text>

    </g>

  );
};


export default class BOOKUSERCHART extends PureComponent {

  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/hqnrgxpj/';

  state = {

    activeIndex: 0,

    data: [
     
      { name: 'vp', value: 1 },
      
      { name: 'Dirctor', value: 0 },
      
      { name: 'Normal', value: 0 },

    ],

  };


  componentDidMount() {

    // this.fetch_user();

  }

  // fecth slot

  fetch_user = () => {

    client.query({

      query: GET_SLOT,

      fetchPolicy: 'no-cache',

    }).then(result => {

      console.log(result.data.getSlot.user);
      if(result.data.getSlot.user[0].value == 0 && result.data.getSlot.user[1].value == 0 && result.data.getSlot.user[2].value == 0){
        this.setState({ data: [
     
          { name: 'NO USER', value: 1 },
        
        ] });
      }else{
        this.setState({ data: result.data.getSlot.user });

      }

    });

  }

  onPieEnter = (data, index) => {

    this.setState({

      activeIndex: index,

    });

  };

  render() {

    const{data}=this.state;

    return (

      <PieChart width={400} height={400} className='mx-auto' style={{ top: '-5em' }}>

        <Pie

          activeIndex={this.state.activeIndex}

          activeShape={renderActiveShape}

          data={data}

          cx={200}

          cy={200}

          innerRadius={60}

          outerRadius={80}

          fill="#8884d8"

          dataKey="value"

          onMouseEnter={this.onPieEnter}
        />
      </PieChart>
    );
  }
}
