import gql from "graphql-tag";

export const ADD_ADMIN_REPORT = gql`
    mutation ADD_ADMINREPORT($admin_id: ID,$file:Upload,$slot_no:String,$slot_name:String,$description:String,$lang:String) {   
        admin_addRepot(admin_id:$admin_id,file: $file,slot_no:$slot_no,slot_name:$slot_name,description:$description,lang:$lang)
        {
            msg,
            status,
            data
        }
    }
`;
export const ADD_USER_REPORT = gql`
    mutation ADD_USERREPORT($report_userID: ID,$file:Upload,$description:String,$lang:String) {   
        addReport(report_userID:$report_userID,file: $file,description:$description,lang:$lang)
        {
            msg,
            status,
            data
        }
    }
`;