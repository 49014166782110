import ApolloClient from 'apollo-boost';
const { createUploadLink } = require('apollo-upload-client')

// export const URI = 'http://localhost:8008'; //for local
// export const URI = 'http://tryout.waioz.com:8008';
// export const URI = 'https://tryout.waioz.com:8008'; // when cloned
// export const URI = 'http://192.168.0.33:8888';
// export const URI = 'https://essity.gravipark.com';   
// export const URI = "http://18.221.96.242"; //for live
export const URI = "https://gepp.gravipark.com"; //for live(new)

//------------------ scoket url:--------------------//
// export const W_URI = `ws://localhost:8008/graphql`; //for local
// export const W_URI =`ws://192.168.0.33:8888/graphql`;
// export const W_URI =`ws://tryout.waioz.com:8008/graphql`;
// export const W_URI =`wss://tryout.waioz.com:8008/graphql`; // when cloned
// export const W_URI = `wss://essity.gravipark.com/graphql`;
// export const W_URI = 'ws://18.221.96.242/graphql'; //for live
export const W_URI = 'wss://gepp.gravipark.com/graphql'; //for live (new)

export const client = new ApolloClient({
      uri: `${URI}/graphql`,
});
