import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Redirect, Route, BrowserRouter, } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './scss/table.scss';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import { client } from "./apollo";
import Login from './component/Admin/Login/Login';
import Terms from './component/Admin/Common/terms';
import User from './component/Admin/User/User';
import Dashboard from './component/Admin/Dashboard/Dashboard';
import Slot_Template from './component/Admin/Slot/Slot_Template';
import Report_Template from './component/Admin/Report/Report_Template';
import Booking_Template from './component/Admin/Booking/Booking_Template';
import Report_ViewTemplate from './component/Admin/Report/Report_ViewTemplate';
import Static_Template from './component/Admin/Static/Static_Template';
import AddStaticContent from './component/Admin/Static/AddStaticContent';
import PenalityTemplate from './component/Admin/Penality/PenalityTemplate';
import PenalityDetailTemplate from './component/Admin/Penality/PenalityDetailTemplate';
import HolidayTemplate from './component/Admin/Holiday/HolidayTemplate';
import Archive_Template from './component/Admin/Archive/Archive_Template';
import Add_Report_Template from './component/Admin/Report/Add_Report_Template';
import Add_Penalty_Template from './component/Admin/Report/Add_Penalty_Template';

import {Error} from './component/Layout/Error';
import Admin from './component/Admin/Subadmin/Admin';
function PrivateRoute({ component: Component, ...rest }) {
  console.log({ ...rest });
  return (
    <Route
      {...rest}
      render={props =>
            localStorage.getItem('status')==="success" ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                // state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}
ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ApolloProviderHooks client={client}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/avisodeprivacidad" component={Terms} />
          <PrivateRoute path="/admin-dashboard" component={Dashboard} />
          <PrivateRoute path="/admin-slot" component={Slot_Template} />
          <PrivateRoute path="/admin-report" component={Report_Template} />
          <PrivateRoute path="/admin-user" component={User} />
          <PrivateRoute path="/admin-booking" component={Booking_Template} />
          <PrivateRoute path="/admin-reportView" component={Report_ViewTemplate} />
          <PrivateRoute path="/admin-static" component={Static_Template} />
          <PrivateRoute path="/admin-add-static-content" component={AddStaticContent} />
          {/* <PrivateRoute path="/admin-penality" component={PenalityTemplate} /> */}
          {/* <PrivateRoute path="/admin-penality-detail" component={PenalityDetailTemplate} /> */}
          <PrivateRoute path="/admin-holiday" component={HolidayTemplate} />
          <PrivateRoute path="/admin-archive" component={Archive_Template} />
          <PrivateRoute path="/admin-add-report" component={Add_Report_Template} />
          {/* <PrivateRoute path="/admin-add-penalty" component={Add_Penalty_Template} /> */}
          <PrivateRoute path="/admin-admin" component={Admin} />
          <Route component={Error} />
        </Switch>
      </ApolloProviderHooks>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
