import React, { useCallback } from "react";
import { Layout, Icon, Avatar, LocaleProvider, Collapse, DatePicker, Popover, Select, Form, Spin, Input, Tooltip, Button, message, Popconfirm, Typography, Row, Col, Card, Table, Modal, Tag, Upload } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment";
import fr_FR from 'antd/es/locale-provider/en_US';
import 'moment/locale/es-us';
// import { DatePicker } from 'antd';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import TemplateSider from '../../Layout/TemplateSider';
import { TemplateHeader, ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import gql from "graphql-tag";
import { URI, client } from "../../../graphql/Client";
import { DOWNLOAD_BOOKING } from '../../../graphql/Penalty';
import { Alert } from '../../Layout/Files';
moment.locale('es');
const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;
const { Option, OptGroup } = Select;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { Panel } = Collapse;
const GET_BOOKING = gql`
  query Getbooking($limit: Int!, $page: Int) {
    booking(limit: $limit, page: $page) {
      totalDocs
      totalPages
      docs
      page
      _id,
      user_id,
      date,
      slot_id,
      slots,
      reservation,
      category,
      category_type
      status,
      pension_time,
      msg,
      date_formate,
    } 
  }
`;
const BOOKING = gql`
  query booking($lang: String, $email: String,$date:String,$slot_id:ID) {
    find_slot(lang: $lang, email: $email,date:$date,slot_id:$slot_id) {
      _id,
      status,
      msg,
    } 
  }
`;
const SEARCH_TANDEM_SLOT = gql`
  query searchtandemslot($sno: String, $sname: String,$date:String,$category_type:String) {
      search_tandem_slot(sno: $sno, sname: $sname,date:$date,category_type:$category_type) {
      _id
      reservation
      status
      msg
      user_id
      users
      slot_id
  }
}
`;

const SEARCH_BOOK = gql`
query searchbooking($lang: String, $data: JSON) {
      search_book(lang: $lang, data: $data) {
      _id,
      totalDocs
      totalPages
      docs
      page
      user_id,
      date,
      slot_id,
      slots,
      reservation,
      category,
      status,
      pension_time,
      msg,
      date_formate,
    } 
  }
`;
const FIND_BOOK = gql`
  query findbooking($lang: String, $data: String) {
    find_book(lang: $lang, data: $data) {
      totalDocs
      totalPages
      docs
      page
      _id,
      user_id,
      date,
      slot_id,
      slots,
      reservation,
      category,
      status,
      pension_time,
      msg,
      date_formate,
    } 
  }
`;

const FIND_SLOTS = gql`
  query findslots($lang: String, $date: String) {
    find_Availableslot(lang: $lang, date: $date) {
      _id,
      date,
      slot_name,
      slot_no,
      category,
      status,
      msg,
    } 
  }
`;


const download_week = gql`
  query Download_Week($lang: String, $date: String) {
      downloadweek(lang: $lang, date: $date) {
      status,
      msg,
    } 
  }
`;

const DOWNLOAD_BOOKING_DATE = gql`
  query DOWNLOADBOOKINGDATE( $date: String) {
      download_all_date( date: $date) {
      status,
      msg,
    } 
  }
`;


export const UPDATE_BOOKING = gql`
    mutation UPDATEBOOKING($_id:ID,$status:String, $slot_id:ID, $reservation: String, $date:String,$lang:String) {   
        update_booking(_id:$_id,status: $status,slot_id: $slot_id, reservation: $reservation,date:$date,lang:$lang)
        {
            msg
            status         
        }
}`
const CANCEL_BOOKING = gql`
      mutation CANCELBOOKING($_id:ID,$lang:String,$admin:String)  {
            cancel_booking(_id:$_id,lang:$lang,admin:$admin){
                  msg   
                  status      
             }
      }
`;
const DELETE_BOOKING = gql`
      mutation DELETBOOKING($_id:ID,$lang:String)  {
            delete_booking(_id:$_id,lang:$lang){
                  msg,     
                  status       
            }
      }
`;
const DELETE_ALL_BOOKING = gql`
      mutation DELETALLBOOKING($lang:String,$option:String)  {
            delete_all_booking(lang:$lang,option:$option){
                  msg,     
                  status       
            }
      }
`;

class Booking_Manage extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  en: 0,
                  u_spin: false,
                  _id: '',
                  u_idb: "",
                  ub_id: "", ubsln: "", ubslno: "",
                  udates: '',
                  visible_pop: false,
                  reportVisible: 0,
                  book: 0,
                  slots: [],
                  u_id: '', uname: "", udate: "", usln: "", uslno: "", urs: "", usta: "",
                  bemail: '', bdate: "",
                  user_model_visible: false,
                  dataSource: [],
                  loading: 1,
                  pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0,
                        simple: true,
                  },
                  s_email: '', s_rn: '', s_name: '', s_ut: '', s_sname: "", s_date: '', s_slotname: '', s_slotno: '',
                  d_book: 'ok',
                  delete_book_date: moment(),
            };
      }
      componentDidMount() {
            this.fetch_User();
            // this.download();
      }
      // fecth booking
      fetch_User = (visible) => {
            client.query({
                  query: GET_BOOKING,
                  variables: { limit: this.state.pagination.pageSize, page: this.state.pagination.current, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // this.download();
                  console.log(result.data);
                  const pagination = { ...this.state.pagination };
                  pagination.total = result.data.booking.totalDocs;
                  console.log(pagination);
                  this.setState({ pagination, book: 0, reportVisible: 0, loading: false, user_model_visible: visible, dataSource: result.data.booking.docs });
            });
      }
      download = async () => {
            await client.query({
                  query: DOWNLOAD_BOOKING,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // Alert(result.data.deletePenalty);
            });
      }
      download_date = async (date) => {
            var data = moment().format('MMMM Do YYYY, h:mm:ss a');
            this.setState({ delete_book_date: data });
            await client.query({
                  query: DOWNLOAD_BOOKING_DATE,
                  variables: { date: data },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // Alert(result.data.deletePenalty);
            });
      }
      // cancel a booking
      CancelBooking = async (_id) => {
            console.log(_id);
            await client.mutate({
                  mutation: CANCEL_BOOKING,
                  variables: { _id: _id, lang: ens, admin: 'yes' },
            }).then(result => {
                  console.log(result.data);
                  Alert(result.data.cancel_booking);
                  this.fetch_User();
            });
      };
      // delete a booking
      DeleteBooking = async (_id) => {
            // console.log(_id);
            await client.mutate({
                  mutation: DELETE_BOOKING,
                  variables: { _id: _id, lang: ens },
            }).then(result => {
                  console.log(result.data);
                  Alert(result.data.delete_booking);
                  this.fetch_User();
            });
      };

      DeleteAllBooking = async () => {
            console.log("delete all boking");
            this.setState({ visible_pop: false });
            await this.download_date();
            await client.mutate({
                  mutation: DELETE_ALL_BOOKING,
                  variables: { lang: ens },
            }).then(result => {
                  Alert(result.data.delete_all_booking);
                  this.fetch_User();
            });
      }
      hide = () => {
            this.setState({
                  visible_pop: false,
            });
      }

      handleVisibleChange = (visible_pop) => {
            this.setState({ visible_pop: true });
      }

      handleTableChange = pagination => {
            const pager = { ...pagination };
            pager.current = pagination.current;
            client.query({
                  query: GET_BOOKING,
                  variables: { limit: pager.pageSize, page: pager.current },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  const pagination = { ...this.state.pagination };
                  pagination.total = result.data.booking.totalDocs;
                  pagination.current = result.data.booking.page;
                  console.log(pagination);
                  this.setState({ pagination, loading: false, dataSource: result.data.booking.docs });
            });
      };
      
      res_search = value => {
            const { s_name, s_rn, s_ut, s_sname, s_email, s_slotname, s_slotno, s_date } = this.state;
            var slot_array = [];
            var user_array = [];
            var book_array = [];
            let book_date="";

            // user data
            if (s_email !== '') {
                  user_array.push( {"email": {"$regex": `${s_email}`, "$options": "i" }});
            }
            if (s_name !== '') {
                  user_array.push( {"user_name":{ "$regex": `${s_name}`, "$options": "i" }});
            }
            if (s_sname !== '') {
                  user_array.push( {"sur_name": { "$regex": `${s_sname}`, "$options": "i" }});
            }
            if (s_ut !== '') {
                  user_array.push({"user_type": { "$regex": `${s_ut}`, "$options": "i" }});
            }
            // booking data
            if (s_rn !== '') {
                  book_array.push( {"reservation": { "$regex": `${s_rn}`, "$options": "i" }});
            }
            if (s_date !== '' && s_date !== null) {
                  book_date= moment(s_date).format('YYYY-MM-DD');
            }
            // slot data
            if (s_slotno !== '') {
                  slot_array.push( {"slot_no": { "$regex": `${s_slotno}`, "$options": "i" }});
            } 
            else if (s_slotname !== '') {
                  slot_array.push( {"slot_name": { "$regex": `${s_slotname}`, "$options": "i" }});
            } 

            client.query({
                  query: SEARCH_BOOK,
                  variables: { lang: ens, data: { 'data1': slot_array, 'data2': book_array, 'data3': user_array,date:book_date  } },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  if (result.data.search_book.totalDocs != 0) {
                        this.setState({ loading: false, dataSource: result.data.search_book.docs });
                  } else {
                        Alert({ msg: "INVALID DATA", status: 'failed' });
                  }
            });
      }
      bookingVisible = (_id) => {
            console.log(_id);
            client.query({
                  query: FIND_BOOK,
                  variables: { lang: ens, data: _id },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  if (result.data.find_book.totalDocs != 0) {
                        if (result.data.find_book.docs[0].slot_id == null) {
                              this.setState({
                                    reportVisible: 1,
                                    u_idb: result.data.find_book.docs[0]._id,
                                    uname: result.data.find_book.docs[0].user_id.user_name,
                                    udate: result.data.find_book.docs[0].date_formate,
                                    udates: result.data.find_book.docs[0].date_formate,
                                    udate1: result.data.find_book.docs[0].date_formate,
                                    usln: '',
                                    uslno: '',
                                    urs: result.data.find_book.docs[0].reservation,
                                    usta: result.data.find_book.docs[0].status,
                                    u_id: '',
                              });
                        } else {
                              this.setState({
                                    reportVisible: 1,
                                    u_idb: result.data.find_book.docs[0]._id,
                                    uname: result.data.find_book.docs[0].user_id.user_name,
                                    udate: result.data.find_book.docs[0].date_formate,
                                    udates: result.data.find_book.docs[0].date_formate,
                                    udate1: result.data.find_book.docs[0].date_formate,
                                    usln: result.data.find_book.docs[0].slot_id.slot_name,
                                    uslno: result.data.find_book.docs[0].slot_id.slot_no,
                                    urs: result.data.find_book.docs[0].reservation,
                                    usta: result.data.find_book.docs[0].status,
                                    u_id: result.data.find_book.docs[0].slot_id._id,
                              });
                        }
                  } else {
                        Alert({ msg: "INVALID DATA", status: 'failed' });
                  }
                  this.uslot();
            });
      }
      books = () => {
            console.log(typeof this.state.bemail);
            console.log(this.state.bdate);
            console.log(this.state.ub_id);
            if (this.state.bemail !== '' && this.state.bdate !== '') {
                  this.setState({ u_spin: true });
                  client.query({
                        query: BOOKING,
                        variables: { lang: ens, email: this.state.bemail, date: this.state.bdate, slot_id: this.state.ub_id },
                        fetchPolicy: 'no-cache',
                  }).then(result => {
                        console.log(result.data);
                        Alert(result.data.find_slot);
                        this.setState({ u_spin: false, bemail: '', bdate: '', ub_id: '', ubsln: '', ubslno: '' });
                        this.fetch_User();
                  });
            } else {
                  console.log("ok");
                  Alert({ msg: "Please enter email and date", status: 'failed' });
            }
      }
      uslot = (value) => {
            var val = '';
            console.log(value);
            console.log(this.state.udate1);
            if (value === '' || value === undefined) {
                  val = this.state.udate1;
            } else {
                  val = value;
            }
            console.log(val);
            client.query({
                  query: FIND_SLOTS,
                  variables: { lang: ens, date: val },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // console.log(result.data);
                  this.setState({ slots: result.data.find_Availableslot, udate: value });
            });
      }
      selectSlot = (value, key) => {
            console.log(value);
            console.log();
            var data = key.key.split('_');
            this.setState({ u_id: value, usln: data[0], uslno: data[1] });
      }
      selectSlotbook = (value, key) => {
            console.log(value);
            console.log();
            var data = key.key.split('_');
            this.setState({ ub_id: value, ubsln: data[0], ubslno: data[1] });
      }
      updatebooking = (value) => {
            // this.setState({ u_spin: true });
            console.log(this.state.udate);
            console.log(this.state.udate1);
            var dates = '';
            if (this.state.udate === null || this.state.udate === '' || this.state.udate === undefined) {
                  dates = this.state.udate1;
            } else {
                  dates = this.state.udate;
            }
            console.log(dates);
            if (this.state.u_id !== '' && this.state.u_idb !== '' && dates !== '') {
                  client.query({
                        query: UPDATE_BOOKING,
                        variables: { lang: ens, slot_id: this.state.u_id, _id: this.state.u_idb, date: dates },
                        fetchPolicy: 'no-cache',
                  }).then(result => {
                        console.log(result.data);
                        Alert(result.data.update_booking);
                        this.setState({ u_spin: false });
                        this.fetch_User();
                  });
            } else {
                  this.setState({ u_spin: false });
                  Alert({ msg: 'PLEASE FILL ALL DATA' });
            }
      }
      onweek = (date, dateString) => {
            console.log(dateString);
            if (dateString.length === 0) {
                  this.setState({ d_book: "ok" });
            }
            client.query({
                  query: download_week,
                  variables: { lang: ens, date: dateString },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // console.log(result.data);
                  // Alert(result.data);
                  this.setState({ d_book: "not ok" });
            });
      }

      info = (data, date) => {
            client.query({
                  query: SEARCH_TANDEM_SLOT,
                  variables: {
                        'sname': data.slot_name,
                        'sno': data.slot_no,
                        'category_type': data.category,
                        'date': date
                  },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  if (result.data && result.data.search_tandem_slot) {
                        Modal.info({
                              title: result.data.search_tandem_slot.msg,
                              content: (<>
                                     {result.data.search_tandem_slot.slot_id &&  <div className="d-flex justify-content-between">
                                          <div>{result.data.search_tandem_slot.slot_id.slot_name}{result.data.search_tandem_slot.slot_id.slot_no}</div>
                                          <div>{result.data.search_tandem_slot.slot_id.category_type}</div>
                                    </div>}
                                    {result.data.search_tandem_slot.user_id && <div className="d-flex justify-content-between">
                                          <div>{result.data.search_tandem_slot.user_id.sure_name}{result.data.search_tandem_slot.user_id.user_name}</div>
                                          <div>{result.data.search_tandem_slot.user_id.email}</div>
                                    </div>
                                    }
                              </>
                              ),
                              onOk() { },
                        });
                  } else {
                        Alert({ msg: "Near slot is empty", status: 'failed' });
                  }
            });

      }
      render() {
            const { uname, udate, usln, uslno, ubsln, ubslno, urs, usta, bemail, bdate, udates } = this.state;
            strings.setLanguage(ens);
            const columns = [
                  {
                        title: <span>{strings.email}</span>,
                        dataIndex: '',
                        key: 'user_id.email',
                        width: 20,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="User Name">{record.user_id === null ? '' : record.user_id.email}</span>;
                        }
                  },
                  {
                        title: <span>{strings.date}</span>,
                        dataIndex: '',
                        key: 'date',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Date">{record.date_formate}</span>;
                        }
                  },
                  {
                        title: <span>{strings.sln}</span>,
                        dataIndex: '',
                        key: 'slot_name',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              if (record.slot_id === null || record.slot_id === undefined) {
                                    return <span title="Slot_name"></span>;
                              } else {
                                    return <span title="Slot_name">{record.slot_id.slot_name}</span>;
                              }
                        }
                  },
                  {
                        title: <span>{strings.slno}</span>,
                        dataIndex: '',
                        key: 'slots[0].slot_no',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              if (record.slot_id === null || record.slot_id === undefined) {
                                    return <span title="Slot_name"></span>;
                              } else {
                                    if (record.slot_id.category_type && record.slot_id.category === "TANDEM") {
                                          return <span title="Slot_no"><div>{record.slot_id.slot_no}</div>
                                                <Button type="link" onClick={() => { this.info(record.slot_id) }}>view other tandem</Button>
                                          </span>;
                                    } else {
                                          return <span title="Slot_no">{record.slot_id.slot_no}</span>;
                                    }

                              }
                        }
                  },
                  {
                        title: <span>{strings.rs}</span>,
                        dataIndex: '',
                        key: 'reservation',
                        width: 10,
                        align: 'center',
                        className: 'p-0',
                        render: (text, record) => {
                              return <span title="Reservation">{record.reservation}</span>;
                        }
                  },
                  {
                        title: <span>{strings.st}</span>,
                        dataIndex: '',
                        key: 'status',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Status">{record.status}</span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Tooltip placement="bottom" title={'edit'}>
                                                      <Icon className={record.status === 'finished' || record.status === 'Pension Finished' || record.status === 'canceled' || record.status === 'canceled' ? 'd-none' : ''} type="edit" tabIndex="-1" onClick={() => this.bookingVisible(record._id)} />
                                                </Tooltip>
                                                <Tooltip placement="bottom" title={'cancel'}>
                                                      <Popconfirm title="Sure to cancel?" onConfirm={() => this.CancelBooking(record._id)}>
                                                            <Icon type="close-circle" />
                                                      </Popconfirm>
                                                </Tooltip>
                                                <Tooltip placement="bottom" title={'Delete'}>
                                                      <Popconfirm title="Sure to delete?" onConfirm={() => this.DeleteBooking(record._id)}>
                                                            {/* className={record.status === 'finished' || record.status === 'pension' ? '' : 'd-none'} */}
                                                            <Icon type="delete" />
                                                      </Popconfirm>
                                                </Tooltip>
                                          </div>
                                    );
                              }
                        }
                  },
            ];

            return (
                  <div>
                        <div data-toggle="tooltip" title="Booking Slot" data-placement="left" className="user_add_position">
                              <Icon type="user-add" className="user_add icon_size" onClick={() => this.setState({ book: 1 })} />
                        </div>

                        <Row className="mb-4">
                              <Collapse activeKey={this.state.openPanel} destroyInactivePanel={true}>
                                    <Panel header={
                                          <Row>
                                                <Col lg={24}>
                                                      <Row>
                                                            <Col lg={12}>
                                                                  <Input placeholder="Email" onChange={(e) => { this.setState({ s_email: e.target.value }) }} />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <Button className="ml-4" type="primary" onClick={this.res_search} >Search</Button>
                                                                  <Button className="ml-4" type="dashed" onClick={() => { this.state.openPanel === '1' ? this.setState({ openPanel: '0' }) : this.setState({ openPanel: '1' }) }} >Advanced Search</Button>
                                                                  <Button className="ml-4 float-right" type="primary" onClick={this.fetch_User} >ALL BOOKINGS</Button>
                                                            </Col>
                                                      </Row>
                                                </Col>
                                          </Row>
                                    } key="1">
                                          <Row gutter={12}>
                                                <Col lg={2}>
                                                      <Input placeholder="Sur Name" onChange={(e) => { this.setState({ s_sname: e.target.value }) }} />
                                                </Col>
                                                <Col lg={6}>
                                                      <Input placeholder="Name" onChange={(e) => { this.setState({ s_name: e.target.value }) }} />
                                                </Col>
                                                <Col lg={8}>
                                                      <Input placeholder="Reservation No" onChange={(e) => { this.setState({ s_rn: e.target.value }) }} />
                                                </Col>
                                                <Col lg={8}>
                                                      <Select defaultValue="" style={{ width: "-webkit-fill-available" }} onChange={(value) => { this.setState({ s_ut: value },()=>console.log(this.state)) }}>
                                                            <Option value="">Select UserType</Option>
                                                            <Option value="FIXED">Fixed</Option>
                                                            <Option value="POOL">Pool</Option>
                                                      </Select>
                                                </Col>
                                          </Row>
                                          <Row gutter={12} className="mt-4">
                                                <Col lg={4}>
                                                      <Input placeholder="Slot Name" onChange={(e) => { this.setState({ s_slotname: e.target.value }) }} />
                                                </Col>
                                                <Col lg={4}>
                                                      <Input placeholder="Slot No" onChange={(e) => { this.setState({ s_slotno: e.target.value }) }} />
                                                </Col>
                                                <Col lg={8}>
                                                      <LocaleProvider locale={fr_FR}>
                                                            <DatePicker format='YYYY/MM/DD' style={{ width: '-webkit-fill-available' }} onChange={(value) => { this.setState({ s_date: value }) }} placeholder="Select Date" />
                                                      </LocaleProvider>
                                                </Col>
                                          </Row>
                                    </Panel>

                              </Collapse>
                        </Row>

                        <Modal title="UPDATE BOOKING" style={{ top: 20 }} visible={this.state.reportVisible} onCancel={() => this.setState({ reportVisible: 0 })} footer={[]} >
                              <Spin tip="Loading..." spinning={this.state.u_spin}> </Spin>
                              <Row gutter={16}>
                                    <Col span={12}>
                                          <Input disabled={1} placeholder="NAME" value={uname} />
                                    </Col>
                                    <Col span={12}>
                                          <LocaleProvider locale={fr_FR}>
                                                <DatePicker style={{ width: '-webkit-fill-available' }} onChange={(value) => { this.uslot(value); this.setState({ usln: '', uslno: '', u_id: '' }) }} placeholder={udates} />
                                          </LocaleProvider>
                                    </Col>
                              </Row>
                              <Row gutter={16} className="my-4">
                                    <Col span={8}>
                                          <Select defaultValue="Slot" value={usln + "_" + uslno} style={{ width: '100%' }} onChange={this.selectSlot}>
                                                {this.state.slots.map((data) => {
                                                      return (
                                                            <Option key={data.slot_name + "_" + data.slot_no} value={data._id}>{data.slot_name + "_" + data.slot_no}<span className="float-right">{data.category}</span></Option>
                                                      )
                                                })}
                                          </Select>
                                    </Col>
                              </Row>
                              <Row gutter={16}>
                                    <Col span={12}>
                                          <Input disabled={1} placeholder="RESERVATION" value={urs} />
                                    </Col>
                                    <Col span={12}>
                                          <Input disabled={1} placeholder="STATUS" value={usta} />
                                    </Col>
                              </Row>
                              <Row gutter={16} className="my-4">
                                    <Col span={12}>
                                          <Button className="float-left" onClick={() => this.setState({ reportVisible: 0 })}>Cancel</Button>
                                    </Col>
                                    <Col span={12}>
                                          <Button type="primary" className="float-right" onClick={this.updatebooking}>Submit</Button>
                                    </Col>
                              </Row>
                        </Modal>
                        <Modal title="SLOT BOOKING" style={{ top: 20 }} visible={this.state.book} onCancel={() => this.setState({ book: 0, data: '' })} footer={[]} >
                              <Spin tip="Loading..." spinning={this.state.u_spin}> </Spin>
                              <Row gutter={16}>
                                    <Col span={12}>
                                          <Input placeholder="EMAIL" value={bemail} onChange={(event) => { this.setState({ bemail: event.target.value }) }} />
                                    </Col>
                                    <Col span={12}>
                                          <LocaleProvider locale={fr_FR}>
                                                <DatePicker
                                                      style={{ width: '-webkit-fill-available' }}
                                                      placeholder="DATE"
                                                      value={this.state.bdate}
                                                      onChange={(value) => { this.uslot(value); this.setState({ bdate: value, ubsln: '', ubslno: '' }) }} />
                                                {/* <DatePicker locale={eng}  */}
                                          </LocaleProvider>
                                          {/* <DatePicker onChange={ placeholder="DATE" /> */}
                                    </Col>
                              </Row>
                              <Row gutter={16} className="my-4">
                                    <Col span={8}>
                                          <Select defaultValue="Slot" value={ubsln + "_" + ubslno} style={{ width: "100%" }} onChange={this.selectSlotbook}>
                                                {this.state.slots.map((data) => {
                                                      return (
                                                            <Option key={data.slot_name + "_" + data.slot_no} value={data._id}>{data.slot_name + "_" + data.slot_no}<span className="float-right">{data.category}</span></Option>
                                                      )
                                                })}
                                          </Select>
                                    </Col>
                              </Row>
                              <Row gutter={16} className="my-4">
                                    <Col span={12}>
                                          <Button className="float-left" onClick={() => this.setState({ book: 0 })}>Cancel</Button>
                                    </Col>
                                    <Col span={12}>
                                          <Button type="primary" className="float-right" onClick={this.books}>Submit</Button>
                                    </Col>
                              </Row>
                        </Modal>

                        <div id="no-more-tables">
                              <Table
                                    pagination={this.state.pagination}
                                    // expandedRowRender={record =>
                                    //       <Row>
                                    //             <Col sm={24} lg={12}>
                                    //                   <Row >
                                    //                         <Col sm={12} lg={12}>
                                    //                               <span>{strings.vc}</span>
                                    //                         </Col>
                                    //                         <Col sm={12} lg={12}>
                                    //                               : <Tag color="blue" >{record.user_id === null ? '' : record.user_id.vehicle_color}</Tag>
                                    //                         </Col>
                                    //                   </Row>
                                    //                   <Row className="py-3">
                                    //                         <Col sm={12} lg={12}>
                                    //                               <span>{strings.vlp}</span>
                                    //                         </Col>
                                    //                         <Col sm={12} lg={12}>
                                    //                               : <Tag color="green" >{record.user_id === null ? '' : record.user_id.vehicle_licenseplate}</Tag>
                                    //                         </Col>
                                    //                   </Row>
                                    //                   <Row>
                                    //                         <Col sm={12} lg={12}>
                                    //                               <span>{strings.ut}</span>
                                    //                         </Col>
                                    //                         <Col sm={12} lg={12}>
                                    //                               : <Tag color="green" >{record.slot_id === null || record.slot_id === undefined ? '' : record.slot_id.category}</Tag>
                                    //                         </Col>
                                    //                   </Row>
                                    //             </Col>
                                    //       </Row>}
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    footer={() =>
                                          <div>
                                                <a href={URI + "/document/booking.xlsx"} download>
                                                      <Button type="primary" shape="round" icon="download" >
                                                            Download
                                                      </Button>
                                                </a>
                                                <LocaleProvider locale={fr_FR}>
                                                      <WeekPicker onChange={this.onweek} className="px-3" placeholder="Select week" />
                                                </LocaleProvider>

                                                <a href={URI + "/document/bookingByweek.xlsx"} download>
                                                      <Button type="primary" shape="round" className={this.state.d_book === 'ok' ? "d-none" : ""} icon="download" >
                                                            Download Booked By Week
                                                      </Button>
                                                </a>
                                                <a href={URI + "/document/cancelByweek.xlsx"} download>
                                                      <Button type="primary" shape="round" className={this.state.d_book === 'ok' ? "d-none" : "mx-3"} icon="download" >
                                                            Download Cancel Byweek
                                                      </Button>
                                                </a>

                                                <Popover
                                                      content={
                                                            <div>
                                                                  <a href='#' onClick={this.hide}>NO</a>
                                                                  <a className='float-right' download onClick={this.DeleteAllBooking}>YES</a>

                                                                  {/* <a className='float-right' href={URI + `/document/${this.state.delete_book_date}.xlsx`} download onClick={this.DeleteAllBooking}>YES</a> */}
                                                            </div>}
                                                      title="Sure to delete all finished data ?"
                                                      trigger="click"
                                                      visible={this.state.visible_pop}
                                                >
                                                      <Button onClick={this.handleVisibleChange} className="float-right" type="primary" shape="round" icon="delete"> Delete All Booking</Button>
                                                </Popover>

                                          </div>
                                    }
                                    onChange={this.handleTableChange}
                              />
                        </div>
                  </div>
            );
      }
}
export default Booking_Manage;
