
import React, { useCallback } from "react";
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button,Select, message, Typography, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import TemplateSider from '../../Layout/TemplateSider';
import TemplateHeader, { ens } from '../../Layout/TemplateHeader';
import USERCHART from "./USERCHART";
import REPORTCHART from './REPORTCHART';
import BOOKUSERCHART from './BOOKUSERCHART';
import BOOKSLOTCHART from './BOOKSOLTCHART';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { GET_DASH } from '../../../graphql/chart';
import { client } from "../../../graphql/Client";
import { strings } from "../../Layout/Strings";
import VPEMPTY from "./VPEMPTY";
import DIREMPTY from "./DIREMPTY";


const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

class Dashboard extends React.Component {
    state = {
        // penality: '',
        book: '',
        slot: '',
        reported: '',
        week:'cw',
        week1:'cw',
    };
    componentDidMount() {

        this.fetch_dash();

    }

    // fecth user
    fetch_dash = () => {

        client.query({

            query: GET_DASH,

            fetchPolicy: 'no-cache',

        }).then(result => {

            console.log(result.data.getDash);

            this.setState({
                slot: result.data.getDash.data[0].slot,
                book: result.data.getDash.data[0].book,
                // penality: result.data.getDash.data[0].penality,
                reported: result.data.getDash.data[0].reported,
            });
        });
    }

    handleChange=(value)=> {
        // console.log(value);
        this.setState({week:value});
    }

    handleChange1 = (value) => {
        // console.log(value);
        this.setState({ week1: value });
    }

    render() {
        strings.setLanguage(ens);

        const { book,
            //  penality,
             slot, reported } = this.state;

        return (

            <Layout style={{ height: '100vh' }}>

                <TemplateSider update_collapsed={this.state.collapsed} />

                <Layout>

                    <TemplateHeader toogle={this.onToggle} />

                    <Row gutter={16} className="header_card">

                        <Col md={12} xl={8} className="py-2" >

                            <Link to="/admin-user" >

                                <Card hoverable className="user_card" >

                                    <Row gutter={8}>

                                        <Col span={17} style={{ paddingRight: 0 }}>

                                            <Row><Text className="header_text" type="secondary"><span>{strings.SLOT}</span></Text></Row>

                                            <Row><Text className="header_subtext" type="secondary">{slot}</Text></Row>

                                        </Col>

                                        <Col span={7} className="d-flex">

                                            <Icon style={{ fontSize: 'xx-large' }} type="desktop" theme="outlined" twoToneColor="#007bff" />

                                        </Col>

                                    </Row>

                                </Card>

                            </Link>

                        </Col>

                        <Col md={12} xl={8} className="py-2">

                            <Link to="/admin-booking" >

                                <Card hoverable className="provider_card" >

                                    <Row gutter={8}>

                                        <Col span={17} style={{ paddingRight: 0 }}>

                                            <Row><Text className="header_text" type="secondary"><span>{strings.BOOKING}</span></Text></Row>

                                            <Row><Text className="header_subtext" type="secondary">{book}</Text></Row>

                                        </Col>

                                        <Col span={7} className="d-flex">

                                            <Icon style={{ fontSize: 'xx-large' }} type="book" theme="twoTone" twoToneColor="#52c41a" />

                                        </Col>

                                    </Row>

                                </Card>

                            </Link>

                        </Col>

                        <Col md={12} xl={8} className="py-2">

                            <Link to="/admin-report" >

                                <Card hoverable className="complete_card">

                                    <Row gutter={8}>

                                        <Col span={17} style={{ paddingRight: 0 }}>

                                            <Row><Text className="header_text" type="secondary"><span>{strings.ru}</span></Text></Row>

                                            <Row><Text className="header_subtext" type="secondary">{reported}</Text></Row>

                                        </Col>

                                        <Col span={7} className="d-flex">

                                            <Icon type="camera" style={{ fontSize: 'xx-large' }} theme="twoTone" twoToneColor="#ffc107" />

                                        </Col>

                                    </Row>

                                </Card>

                            </Link>

                        </Col>

                        {/* <Col md={12} xl={6} className="py-2">

                            <Link to="/admin-penality" >

                                <Card hoverable className="exception_card">

                                    <Row gutter={16}>

                                        <Col span={17}>

                                            <Row><Text className="header_text" type="secondary"><span>{strings.pu}</span></Text></Row>

                                            <Row><Text className="header_subtext" type="secondary">{penality}</Text></Row>

                                        </Col>

                                        <Col span={7} className="d-flex">

                                            <Icon type="alert" style={{ fontSize: 'xx-large' }} theme="twoTone" twoToneColor="#f50000" />

                                        </Col>

                                    </Row>

                                </Card>

                            </Link>

                        </Col> */}

                    </Row>

                    <Content className="dash_content">

                        <Row type="flex" justify="center" gutter={16} className="mb-5">

                            <Col sm={24} xl={12} className="py-2">

                                <Card title={<span className="d-lg-flex">{strings.CHART5}
                                    <Select className="float-right d-flex m-auto" defaultValue="cw" style={{ width: 'auto' }} onChange={this.handleChange}>
                                        <Option value="nw">NEXT WEEK</Option>
                                        <Option value="cw">CURRENT WEEK</Option>
                                    </Select>
                             </span>} style={{ height: '27em' }} bordered={true}>

                                    <VPEMPTY week={this.state.week} />

                                </Card>

                            </Col>



                            {/* <Col sm={24} xl={12} className="py-2">

                                <Card title={<span>{strings.CHART6}
                                    <Select className="float-right d-lg-flex m-auto" defaultValue="cw" style={{ width: 'auto' }} onChange={this.handleChange1}>
                                        <Option value="nw">NEXT WEEK</Option>
                                        <Option value="cw">CURRENT WEEK</Option>
                                    </Select></span>} style={{ height: '27em' }} bordered={true}>

                                    <DIREMPTY week1={this.state.week1}  />

                                </Card>

                            </Col>  */}


                        </Row>
{/* 
                        <Row gutter={16} className="mb-5">

                            <Col sm={24} xl={12} className="py-2">

                                <Card title={<span>{strings.CHART1}</span>} style={{ height: '27em' }} bordered={true}>

                                    <BOOKSLOTCHART />

                                </Card>

                            </Col>

                            <Col sm={24} xl={12} className="py-2">

                                <Card title={<span>{strings.CHART2}</span>} style={{ height: '27em' }} bordered={true}>

                                    <BOOKUSERCHART />

                                </Card>

                            </Col>

                        </Row>

                        <Row gutter={16}>

                            <Col xl={12} className="py-2">

                                <Card title={<span>{strings.CHART3}</span>} bordered={true}>

                                    <REPORTCHART />

                                </Card>

                            </Col>


                            <Col xl={12} className="py-2">

                                <Card title={<span>{strings.CHART4}</span>} bordered={true}>

                                    <USERCHART />

                                </Card>

                            </Col>

                        </Row> */}

                    </Content>

                </Layout>

            </Layout >
        );
    }
}


export default Dashboard;
