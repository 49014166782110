

import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import gql from "graphql-tag";
import { Layout, Icon, Badge, AutoComplete, Spin, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table, Modal, Upload, Select } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { UPDATE_SLOT } from '../../../graphql/slot';
import { Alert } from '../../Layout/Files';
import NOIMG from '../../../noimg.png'
import { URI } from '../../../graphql/Client';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import ImageCompressor from 'image-compressor.js';
import { SEARCH_SLOT_REGEX } from "../../../graphql/slot";

const { TextArea } = Input;
const { Option } = Select;

export const Update = (props) => {
      const [slot_no, setslot_no] = React.useState('');
      const [slot_name, setslot_name] = React.useState('');
      const [slot_search, set_slot_search] = React.useState('');
      const [front_slot, set_front_slot] = React.useState('');
      const [back_slot, set_back_slot] = React.useState('');
      const [description, setdescription] = React.useState('');
      const [category, setcategory] = React.useState('');
      const [file, setFile] = React.useState('');
      const [imageUrl, setimageUrl] = React.useState('');
      const [file1, setFile1] = React.useState('');
      const [imageUrl1, setimageUrl1] = React.useState('');
      const [category_type, set_category_type] = React.useState('');
      const [up1, setupt] = React.useState('1');
      const [uploadFile] = useMutation(UPDATE_SLOT, {});
      const [spin, setspin] = React.useState(false);
      const serach_slot_with_regex = useQuery(SEARCH_SLOT_REGEX);

      useEffect(() => {
            console.log(props);
            setslot_no(props.data[0].slot_no);
            setslot_name(props.data[0].slot_name);
            setdescription(props.data[0].description);
            setcategory(props.data[0].category);
            setimageUrl(props.data[0].url);
            setimageUrl1(props.data[0].url_live);
            set_category_type(props.data[0].category_type);
            
      }, [props.data]);

      const getBase64 = (file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                  setimageUrl(reader.result);
            };
            reader.onerror = (error) => {
            };
      }

      const getBase641 = (file) => {
            var reader1 = new FileReader();
            reader1.readAsDataURL(file[0]);
            reader1.onload = () => {
                  setimageUrl1(reader1.result);
            };

            reader1.onerror = (error) => {
                  console.log('Error: ', error);
            };

      }

      const onDrop = async acceptedFiles => {

            console.log(acceptedFiles);


            if (up1 === '2') {

                  await getBase641(acceptedFiles);

                  await new ImageCompressor(acceptedFiles[0], {

                        quality: .6,
                        success(result) {
                              console.log(result);
                              var file = new File([result], result.name);
                              console.log(file);
                              setFile1(file);
                        }
                  });

                  // setFile1(acceptedFiles);

                  setupt('2');


            } else if (up1 === '1') {

                  await getBase64(acceptedFiles);

                  await new ImageCompressor(acceptedFiles[0], {

                        quality: .6,
                        success(result) {
                              console.log(result);
                              var file = new File([result], result.name);
                              console.log(file);
                              setFile(file);
                        }
                  });
                  // setFile(acceptedFiles);

                  setupt('1');

            }


      }

      const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: 0 });
      const onChange = event => setslot_name(event.target.value);
      const onChange1 = event => setslot_no(Number(event.target.value));
      const onChange2 = event => setdescription(event.target.value);
      const onChange3 = value => setcategory(value);
      const oncancel = () => {
            setimageUrl('');
            props.cancelmodel(false);
      }

      const updateSlot = useCallback((event) => {
            setspin(true);
            if (slot_no && slot_name && category) {
                  let input_data = {
                        "_id": props.data[0]._id,
                        "category": category,
                        "slot_no": slot_no,
                        "slot_name": slot_name,
                        "description": description, file, file1, lang: ens
                  }

                  uploadFile({
                        variables: input_data
                  }).then(result => {
                        setspin(false);
                        setimageUrl('');
                        props.updateuser(result.data);
                  });
            } else {
                  setspin(false);
                  Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });

            }
      }, [uploadFile]);

      strings.setLanguage(ens);

      return (

            <div className="gutter-example">
                  <Spin tip="Loading..." spinning={spin}  >
                        <Row gutter={16}>
                              <Col className="gutter-row" span={24}>
                                    <Row gutter={16}>
                                          <Col className="gutter-row" span={8}>
                                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                                      <Badge status="processing" text="" />
                                                      <Input placeholder={strings.sln} value={slot_name} onChange={onChange} />
                                                </div>
                                          </Col>
                                          <Col className="gutter-row" span={8}>
                                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                                      <Badge status="processing" text="" />
                                                      <Input placeholder={strings.slno} value={slot_no} onChange={onChange1} />
                                                </div>
                                          </Col>
                                          <Col className="gutter-row" span={8}>
                                                <div className='d-flex' style={{ marginBottom: 16 }}>
                                                      <Badge status="processing" text="" />
                                                      <Select defaultValue={strings.cat} value={category} style={{width: '100%'}} onChange={onChange3}>
                                                            <Option value="WORKING_STATION">Working Station</Option>
                                                            <Option value="CASUAL_STATION">Casual Station</Option>
                                                      </Select>
                                                </div>
                                          </Col>
                                    </Row>
                                    {(category && category == "TANDEM") && <>  <Row gutter={16} className="mb-4">
                                          <Col className="gutter-row" span={24}>
                                                <Card> {category_type === 'tandem_front' ? "TANDEM FRONT" : "TANDEM BACK"}</Card>
                                          </Col>
                                    </Row></> }
                                    <Row gutter={16}>
                                          <Col className="gutter-row" span={16}>
                                                <div style={{ marginBottom: 16 }}>
                                                      <TextArea placeholder={strings.ds} value={description} onChange={onChange2} rows={6} />
                                                </div>
                                          </Col>
                                          <Col className="gutter-row" span={8}>
                                                <div className="d-flex" style={{ marginBottom: 16 }}>
                                                      <Icon type="caret-left" className={up1 === '1' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('1') }} />
                                                      <div className={up1 === '1' ? 'vw-100 gutter-box' : up1 === '' ? 'vw-100 gutter-box' : 'd-none'}>
                                                            <div {...getRootProps()} className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "slot_img_upload d-flex" : "d-none"} >
                                                                  <input {...getInputProps()} />
                                                                  {isDragActive ? (
                                                                        <p>Drop the files here ...</p>
                                                                  ) : (

                                                                        <Icon type="cloud-upload" className="m-auto icon" />
                                                                  )}
                                                            </div>
                                                            <img src={imageUrl} className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />
                                                            <Icon type="delete" theme="twoTone" className={imageUrl === URI + '/images/undefined' || imageUrl === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl(''); setFile(''); }} />
                                                      </div>
                                                      <div className={up1 === '2' ? 'vw-100 gutter-box' : 'd-none'}>
                                                            <div {...getRootProps()} className={imageUrl1 === '' ? "slot_img_upload d-flex" : "d-none"} >
                                                                  <input {...getInputProps()} />
                                                                  {isDragActive ? (
                                                                        <p>Drop the files here ...</p>
                                                                  ) : (

                                                                        <Icon type="cloud-upload" className="m-auto icon" />
                                                                  )}
                                                            </div>
                                                            <img src={imageUrl1} className={imageUrl1 === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '8.5em' }} />
                                                            <Icon type="delete" theme="twoTone" className={imageUrl1 === '' ? "d-none" : "m-auto d-block"} onClick={() => { setimageUrl1(''); setFile1(''); }} />
                                                      </div>
                                                      <Icon type="caret-right" className={up1 === '2' ? 'd-none' : up1 === '' ? 'd-none' : 'align-self-center'} onClick={() => { setupt('2') }} />
                                                </div>
                                          </Col>
                                    </Row>

                                    <Row>
                                          <Col>
                                                <Button key="back" onClick={oncancel}> Cancel </Button>
                                                <Button key="submit" className="float-right" type="primary" onClick={updateSlot}> Update </Button>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                  </Spin>
            </div>

      );
};
